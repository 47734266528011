import Link from "next/link";
import Logo from "../logo";
import { RiLinkedinBoxFill } from "react-icons/ri";
import Image from "next/image";

const navigation = {
  product: [
    { name: "Features", href: "/#features" },
    {
      name: "Knowledge Base",
      href: "https://support.easyslr.com/hc/en-us",
      openInNewPage: true,
    },
    { name: "FAQs", href: "/#faqs" },
  ],
  company: [
    { name: "About Us", href: "/about-us" },
    {
      name: "Research",
      href: "/welcome/ispor-us-2024#session",
      openInNewPage: true,
    },
    {
      name: "Careers",
      href: "https://wellfound.com/company/easyslr",
      openInNewPage: true,
    },
    {
      name: "Blog",
      href: "/blog/preseed-announcement",
      openInNewPage: true,
    },
  ],
  legal: [
    { name: "Privacy Policy", href: "/privacy", openInNewPage: true },
    { name: "Terms of Service", href: "/terms", openInNewPage: true },
    {
      name: "Trust Centre",
      // href: "https://in.sprinto.com/trust-center/view/6af008e9-7656-443c-8058-56c60dd2e0a8",
      href: "https://trustcenter.easyslr.com/",
      openInNewPage: true,
    },
  ],
  address: [
    {
      name: (
        <div className="text-sm leading-8 text-opacity-80 hover:text-opacity-100">
          {/* Simplify SLR Private Limited */}
          Minarch Tower, <br /> Sector 44, <br /> Gurugram, HR <br />
          India 122003
          {/* Suite 506, <br /> AIPL Joystreet, <br /> Sector 66, Gurugram <br />
          HR, India - 122018 */}
        </div>
      ),
      // href: "#",
    },
  ],
  social: [
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/easyslr/",
      icon: () => <RiLinkedinBoxFill className="h-6 w-6" />,
    },
    {
      name: "X",
      href: "https://twitter.com/EasySLR",

      icon: () => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="h-6 w-6"
        >
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/easyslr/",
      icon: () => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="h-6 w-6"
        >
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com/@easyslr",
      iconCss: "",
      icon: () => (
        <svg
          fill="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className={"h-7 w-7"}
        >
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
};

const footerItemCss =
  "text-sm leading-6 text-opacity-80 hover:text-opacity-100";

type FooterItemType = {
  href?: string;
  name: string | JSX.Element;
};

const FooterColumn = ({
  name,
  columnItems,
}: {
  name: string;
  columnItems: FooterItemType[];
}) => {
  return (
    <>
      <h3 className="text-sm font-semibold leading-6">{name}</h3>
      <ul role="list" className="mt-6 space-y-4">
        {columnItems.map((item, index) => (
          <FooterColumnItem key={index} {...item} />
        ))}
      </ul>
    </>
  );
};

const FooterColumnItem = ({
  href,
  name,
  openInNewPage,
}: {
  href?: string;
  name: string | JSX.Element;
  openInNewPage?: boolean;
}) => {
  return (
    <li>
      {!href ? (
        name
      ) : (
        <Link
          href={href}
          target={openInNewPage ? "_blank" : "_self"}
          className={footerItemCss}
        >
          {name}
        </Link>
      )}
    </li>
  );
};

export default function Footer() {
  const today = new Date();
  const currentYear = today.getFullYear();
  return (
    <footer className="" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-8xl px-6 pb-8 pt-16 sm:pt-24 lg:px-12 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <div className="flex lg:flex-1">
              <Link href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">EasySLR</span>
                <Logo long className="hidden h-10 w-auto md:block" />
                <Logo long className="h-auto w-40 md:hidden" />
              </Link>
            </div>

            <div className="flex items-center gap-4">
              <Image
                src={"/images/soc.png"}
                width={60}
                height={60}
                alt="soc.png"
                unoptimized
                className="grayscale hover:grayscale-0"
              />
              <Image
                src={"/images/iso.png"}
                width={60}
                height={60}
                alt="iso.png"
                unoptimized
                className="grayscale hover:grayscale-0"
              />
            </div>

            <div className="flex items-center space-x-3">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                  target="_blank"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <FooterColumn name="Product" columnItems={navigation.product} />
              </div>
              <div className="mt-10 md:mt-0">
                <FooterColumn name="Company" columnItems={navigation.company} />
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <FooterColumn name="Legal" columnItems={navigation.legal} />
              </div>
              <div className="mt-10 md:mt-0">
                <FooterColumn name="Address" columnItems={navigation.address} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-16 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5">
            &copy; {currentYear} EasySLR All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
