import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { signIn, useSession } from "next-auth/react";
import Link from "next/link";
import { useState } from "react";
import Logo from "./logo";
import { ModeToggle } from "./mode-toggle";
import { Button } from "./ui/button";
import { cn } from "~/lib/utils";
import useScrollPosition from "~/hooks/use-scroll-position";
import {
  DialogContent,
  DialogTrigger,
  Dialog as ShadcnDialog,
} from "./ui/dialog";
import ContactUs from "./marketing/contact-us";
import { type LeadSource } from "@prisma/client";
import { ArrowRightIcon } from "lucide-react";

const navigation: { name: string; id: string; href: string }[] = [
  // { name: "Product", href: "/#product" },
  { name: "Features", id: "features", href: "/#features" },
  // { name: "Pricing", href: "/#pricing" },
  // { name: "Company", href: "/#company" },
  { name: "Contact Us", id: "contact-us", href: "#contact-us" },
  // { name: "Terms", href: "terms" },
  // { name: "Privacy", href: "privacy" },
  { name: "About Us", id: "about-us", href: "/about-us" },
];

const Topbar = ({
  showLinks = false,
  contactUsSource,
  contactUsCampaign,
}: {
  showLinks?: boolean;
  contactUsSource?: LeadSource;
  contactUsCampaign?: string;
}) => {
  const scrollPos = useScrollPosition();
  const { data: session } = useSession();
  const user = session?.user ?? null;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div
      className={cn(
        "from-bg-white/20 sticky top-0 z-50 bg-gradient-to-b to-transparent",
        scrollPos > 10 ? "backdrop-blur-lg duration-300" : "",
      )}
    >
      <header className="z-[60] mx-auto max-w-8xl">
        <nav
          className="flex items-center justify-between p-6 lg:mx-12 lg:px-0"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">EasySLR</span>
              <Logo long className="hidden h-10 w-auto md:block" />
              <Logo long className="h-auto w-40 md:hidden" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-foreground hover:bg-background hover:text-foreground focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          {showLinks && (
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => {
                if (item.id === "contact-us") {
                  return (
                    <ShadcnDialog key={item.id}>
                      <DialogTrigger asChild>
                        <button
                          key={item.name}
                          className="text-sm font-medium leading-6 text-foreground hover:underline"
                        >
                          {item.name}
                        </button>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-2xl">
                        <ContactUs
                          source={contactUsSource}
                          campaign={contactUsCampaign}
                        />
                      </DialogContent>
                    </ShadcnDialog>
                  );
                }
                return (
                  <Link
                    key={item.name}
                    href={item.href}
                    className="text-sm font-medium leading-6 text-foreground hover:underline"
                  >
                    {item.name}
                  </Link>
                );
              })}
            </div>
          )}
          <div className="hidden items-center space-x-4 lg:flex lg:flex-1 lg:justify-end">
            <div className="flex items-center justify-end">
              <ModeToggle />
            </div>
            {user ? (
              <Link href="/dashboard">
                <Button variant="ghost">Dashboard</Button>
              </Link>
            ) : (
              <>
                <Link href="/auth/signin" className="flex items-center gap-x-1">
                  <Button
                    variant="outline"
                    className="flex items-center gap-x-2"
                  >
                    Sign In
                    {/* <ArrowRightIcon className="h-4 w-4" /> */}
                  </Button>
                </Link>
                <Link href="/auth/signup">
                  <Button className="flex items-center gap-x-2">
                    Sign Up <ArrowRightIcon className="h-4 w-4" />
                  </Button>
                </Link>
              </>
            )}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-background px-6 py-6 text-foreground sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">EasySLR</span>
                <Logo long className="hidden h-10 w-auto md:block" />
                <Logo long className="h-auto w-40 md:hidden" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-foreground"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                {showLinks && (
                  <div className="flex flex-col space-y-2 py-6">
                    {navigation.map((item) => {
                      if (item.id === "contact-us") {
                        return (
                          <ShadcnDialog key={item.id}>
                            <DialogTrigger asChild>
                              <button
                                key={item.name}
                                className="-mx-3 block rounded-lg px-3 py-2 text-left text-base font-semibold leading-7 text-foreground hover:bg-secondary"
                              >
                                {item.name}
                              </button>
                            </DialogTrigger>
                            <DialogContent className="sm:max-w-2xl">
                              <ContactUs />
                            </DialogContent>
                          </ShadcnDialog>
                        );
                      }
                      return (
                        <Link
                          key={item.name}
                          href={item.href}
                          onClick={() => setMobileMenuOpen(false)}
                          className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-foreground hover:bg-secondary"
                        >
                          {item.name}
                        </Link>
                      );
                    })}
                  </div>
                )}
                <div className="flex items-center justify-between py-6">
                  {user ? (
                    <Link href="/dashboard">
                      <Button variant="ghost">Dashboard</Button>
                    </Link>
                  ) : (
                    <div className="flex items-center gap-x-4">
                      <Link href="/auth/signin" className="grow">
                        <Button
                          variant="outline"
                          className="flex grow items-center gap-x-2"
                        >
                          Sign In
                          {/* <ArrowRightIcon className="h-4 w-4" /> */}
                        </Button>
                      </Link>
                      <Link href="/auth/signup" className="grow">
                        <Button className="flex items-center gap-x-2">
                          Sign Up
                          {/* <ArrowRightIcon className="h-4 w-4" /> */}
                        </Button>
                      </Link>
                    </div>
                  )}
                  <ModeToggle />
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
};

export default Topbar;
